export type BasicTheme = 'ivory' | 'darkgreen' | 'default' | 'darkblue';

/**
 * Returns an array of tailwind utility classes that a div should apply to make its contents match a theme
 *
 * Bascially, we started using tailwind after we first implemented themes in the original customPage (chief-marketing) components
 *
 * I'm not exactly sure how themes are implemented over there - i think using common classes, but I had to re-implement it using tailwind over here since at time of writing, those live in a separate repo.
 *
 * For now, this only supports a small small subset of themes - you can see which ones in the sanity-v3 repo under `leadFormThemeOptions`
 */
export const makeTailwindBasicThemeClasses = (themeName: BasicTheme | null): string[] => {
  if (themeName === 'ivory') {
    return ['text-black', 'bg-ivory'];
  }

  if (themeName === 'darkgreen') {
    return ['text-white', 'bg-green-dark'];
  }

  if (themeName === 'darkblue') {
    return ['text-eggshell', 'bg-blue-deep'];
  }

  return ['text-black', 'bg-white'];
};
